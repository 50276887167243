/* Base Style for Footer */
.footer {
  background-color: #f8f9fa; /* Light background */
  padding: 20px 0;
  font-family: 'Arial', sans-serif;
  margin-top: auto; /* Ensures footer is pushed to the bottom */
}

/* Styling for Contact, Address Sections, and General Footer Content */
.footer .col, .footer .col address, .footer .col ul {
  text-align: center;
  padding: 10px 0;
  list-style: none;
  padding: 0;
  font-size: 14px;
  line-height: 1.6;
  color: #666;
}

.footer .col h2 {
  font-weight: bold;
  color: #333;
}

.footer .col a {
  color: #007bff;
  text-decoration: none;
}

.footer .col a:hover, .btn.btn-link:hover {
  text-decoration: underline;
}

/* Rounded Horizontal Line */
.hr.rounded {
  border-top: 2px solid #bbb;
  border-radius: 5px;
}

/* Copyright and Social Media Section */
.copyright-section, .social-icon img {
  text-align: center;
  background-color: #e9ecef;
  font-size: 14px;
  padding: 20px 0;
}

.social-icon img {
  width: 30px; /* Adjust as needed */
  height: auto;
  margin: 0 10px;
}

/* Clearfix */
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/* Custom Link Style for Privacy Policy */
.btn.btn-link {
  color: #007bff;
  text-decoration: none;
  font-size: 16px;
}

/* Responsive Design for smaller screens */
@media (max-width: 768px) {
  .footer .col {
    width: 100%;
    text-align: left;
  }
}

/* Ensure the main content is at least min-height of 100vh */
.body-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


/* Footer CSS */
.footer {
  background-color: #f8f9fa; /* Light background color for the footer */
  padding: 20px 0;
  font-family: 'Arial', sans-serif;
}

.footer-content {
  display: flex;
  justify-content: center; /* Center the items horizontally */
  align-items: center; /* Align items vertically */
  gap: 20px; /* Adds space between address and contact components */
  flex-wrap: wrap; /* Ensures responsiveness */
  max-width: 1200px; /* Adjust based on your preference */
  margin: auto; /* Center the content */
}

/* Ensure SRAddress and SRContact have consistent styling */
.sr-address-container, .sr-contact-container {
  flex: 1; /* Allows the children to grow and fill the space */
  max-width: calc(50% - 10px); /* Adjusts width, accounting for gap */
  min-width: 300px; /* Minimum width before wrapping */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Consistent shadow for depth */
  border-radius: 8px; /* Rounded corners */
  padding: 20px;
  background-color: #fff; /* White background for visibility */
  text-align: center; /* Center the text */
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack the components on smaller screens */
  }
}
