/* BookingForm.css */
.booking-form {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-width: 1144px;
  /* Adjust based on the desktop design */
  margin: auto;
  /* Center the form in the viewport */
}

.radio-buttons {
  display: flex;
  gap: 24px;
  margin-bottom: 20px;
}

.radio-container {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 16px;
}

.radio-container input[type="radio"] {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border-radius: 50%;
  border: none;
}

.radio-container input[type="radio"]:checked {
  background: #113042;
}

.input-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.input-fields label {
  flex: 1 1 auto;
  /* Adjust the label width based on content */
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 5px;
  padding: 5px;
}

input[type="text"],
input[type="datetime-local"],
input[type="tel"] {
  border: none;
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #d7d6d6;
}

button {
  padding: 15px 130px;
  background-color: #113042;
  color: #fff;
  border: none;
  border-radius: 24px;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
}

/* Responsive styles for tablets */
@media (max-width: 768px) {
  .input-fields label {
    /* Adjust label width to ensure proper spacing on tablet */
    flex-basis: calc(50% - 8px);
  }
}

/* Responsive styles for mobile devices */
@media (max-width: 576px) {
  .input-fields label {
    /* Full width labels for mobile */
    flex-basis: 100%;
  }

}

/* Base styles for the loading container */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  text-align: center;
}

/* Spinner styles remain unchanged */
.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #f3f3f3;
  border-top: 6px solid #007BFF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

.loading-text {
  font-weight: bold;
  font-size: 20px;
  color: #007BFF;
  text-align: center;
}

.small-text {
  font-size: 16px;
  color: #555;
  text-align: center;
  margin-top: 5px;
}

/* Animation for the spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Mobile-specific adjustments */
@media (max-width: 767px) {
  .loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 10px;
    z-index: 9999;
  }
}


