

.abt-contents-container {
    margin-top: 0%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/src/assets/img/a-1.png');
    /* background-size: cover; */
    background-position: center;
}

.banner-title-h1{
    left: 38%;
    position: absolute;
    top: 45%;
    color: #ffffff;
    font-size: 49px;
}
.callus-btn {
  position: fixed;
  right: 47px;
  top: 450px;
  z-index: 1000;
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform-origin: bottom right;
}

.callus-btn a {
  display: flex;
  align-items: center;
  background-color: #0dcaf0;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.callus-btn a:hover {
  background-color: #09bee2;
  text-decoration: none;
}

.callus-btn img { 
  margin-right: 8px;
  width: 36% !important;
  transform: scaleX(-1);
}

.whatsapp-btn {
    position: fixed;
    right: 50px;
    top: 300px;
    z-index: 1000;
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform-origin: bottom right;
  }

  .whatsapp-btn a {
    display: flex;
    align-items: center;
    background-color: #25d366;
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .whatsapp-btn a:hover {
    background-color: #20bf59;
    text-decoration: none;
  }

  .whatsapp-btn i {
    font-size: 25px;
    margin-right: 8px;
  }
