/* SRAddress.css */
.sr-address-container {
  background-color: #FFFFFF;
  /* Light grey-blue background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
  text-align: center;
  max-width: 400px;
  /* Adjust based on your layout */
  margin: auto;
  /* Center the container */
}

.sr-address-title {
  color: #007bff;
  /* SR brand blue */
  margin-bottom: 15px;
}

.sr-address {
  color: #333;
  /* Dark grey for text */
  font-size: 16px;
  line-height: 1.5;
}