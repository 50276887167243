.accordion-button::before {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
  content: "";
  background-image: var(--bs-accordion-btn-active-icon);
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
}
  
.accordion-button::after {
  display: none !important;
}

.accordion-button.collapsed::after {
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-position: center;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-position: center;
}