.nav-link.active {
    text-decoration: underline #0dcaf0 !important; 
    color :#0dcaf0 !important;
    text-underline-offset: 10px;
    text-decoration-thickness: 5px !important;
}

.sr-call-button {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 0;
    z-index: 1030;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sr-call-link {
    display: inline-block;
    margin: 0 10px;
}

.sr-call-image {
    width: 60px;
}

.menu-label {
    position: absolute;
    top: 0px;
    right: 5px;
    color: #f8eaea;
    font-size: 16px;
    font-weight: bold;
    z-index: 1;
}

/* SRHeader.css */
.navbar.custom-navbar-bg {
    background-color: #113042 !important;
    height: 75px;
    width: 100%;

}

.text-purple {
    color: #c14842 !important;
    font-weight: bold !important;
    font-size: 0.9rem !important;
    text-decoration: none !important;
}

.text-red {
    color: #dc3545 !important;
    font-size: 0.7rem !important;
}

.nav-link {
    color: #cccccc;
    /* Active Item Color */
    font-weight: bold;
    font-size: 16px;
    text-decoration: none !important;
}

/* Adjustments for mobile view */
@media (max-width: 992px) {
    .text-end.me-3 {
        padding-right: 15px;
    }

    .navbar.custom-navbar-bg {
        background-color: #173141 !important;
        height: 75px;
        width: 100%;

    }

    /* menu css */

    /* Charcoal Gray Menu with Silver Active Item */
    .navbar-custom {
        background-color: #333333;
        /* background-color: #ec1111; */
        /* Menu Item Color */
    }

    .nav-item {
        margin-left: 3px;
        /* Adjust spacing between menu items */
    }

    .nav-link {
        color: #000000;
        /* Active Item Color */
        font-weight: bold;
        font-size: 16px;
        text-decoration: none !important;
        background-color:#ffff !important;
        /* width: 100%; */
        height: 100%;
        padding: auto;
    }

    .nav-link.active {
        background-color: #333333;
        /* Menu Item Color for active menu item */
        border-radius: 5px;
        /* Rounded corners for active item */
        padding: 5px 10px;
        /* Adjust padding for active item */
    }
    .dropdown-menu{
        border:none; 
    }
    .nav-link.active{
        padding:0px 0px 9px; 

    }
}

/* custom form CSS */

.text-purple {
    color: rgb(209, 14, 27);
}

.blink {
    animation: blinker 0.5s linear infinite;
    color: red;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.bi-arrow-down-circle-fill {
    font-size: 1.5rem;
    /* Adjust size as needed */
}

.text-red {
    color: rgb(168, 71, 150);
}

.form-background-image {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/src/assets/img/home_banner_img.png');
    /* background-size: cover; */
    background-position: center;
}
.banner-backdrop{
    position: relative;
}
.banner-title-h1{
    right: 30%;
    position: absolute;
    top:50%;
    color: #ffffff;
    font-size: 49px;
}
.whatsapp-btn {
    position: fixed;
    right: 50px;
    top: 300px;
    z-index: 1000;
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform-origin: bottom right;
  }
  .search-result-text{ 
    margin-top: 10% !important;
   }
  .whatsapp-btn a {
    display: flex;
    align-items: center;
    background-color: #25d366;
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .whatsapp-btn a:hover {
    background-color: #20bf59;
    text-decoration: none;
  }

  .whatsapp-btn i {
    font-size: 25px;
    margin-right: 8px;
  }


  .callus-btn {
    position: fixed;
    right: 47px;
    top: 450px;
    z-index: 1000;
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform-origin: bottom right;
  }

  .callus-btn a {
    display: flex;
    align-items: center;
    background-color: #0dcaf0;
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .callus-btn a:hover {
    background-color: #09bee2;
    text-decoration: none;
  }

  .callus-btn img { 
    margin-right: 8px;
    width: 36% !important;
    transform: scaleX(-1);
  }

 

.homepage-contents-container {
    margin-top: 10%;
}

#myForm{
    position: absolute;
    margin-top: -9%;
    left: 0%;
    width: 100%;
}
.homepage-contents-container{

}

/* Additional styles for readability and aesthetics */

.card {
    background: rgba(255, 255, 255, 0.372);
    /* background-color: #152732; */
}

/* Positioning for the city suggestions list */
.form-container .position-relative .list-group {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    max-height: 200px;
    overflow-y: auto;
}

/* Style adjustments for the list items */
.form-container .list-group-item {
    cursor: pointer;
    border-left: none;
    border-right: none;
    border-radius: 0;
}

.form-container .list-group-item:hover,
.form-container .list-group-item:focus {
    background-color: #f8f9fa;
}

/* info services */

.service-icon {
    width: 100px;
    height: 100px;
}

/* footer css */

/* SRFooter.css */

/* Styles for .footer-content */
.footer-content {
    padding: 20px;
    background-color: #173141;
}

/* Styles for .footer-title */
.footer-title {
    font-size: 20px;
    text-align: center;
}

/* Styles for .footer-subtitle */
.footer-subtitle {
    font-size: 15px;
}

/* Styles for .footer-description */
.footer-description {
    font-size: 14px;
}

/* Styles for .footer-copyright */
.footer-copyright {
    font-size: 14px;
}

/* Styles for .social-icon */
.social-icon {
    display: inline-block;
    margin-right: 10px;
}

/* Styles for .social-icon-img */
.social-icon-img {
    max-width: 50px;
    height: auto;
}

/* Add more styles as needed */

.footer-link {

    transition: color 0.3s ease-in-out;
    color: #ffffff !important;
}

.footer-link:hover {
    color: var(--bs-light);
    /* Lighter or different color on hover */
    text-decoration: underline;
    /* Optional: underline on hover for clear interaction */
}

/* App.css or another stylesheet */
.h1-small {
    font-size: 18px;
    /* Adjust the size as needed */
    text-align: center;
    margin: 0.5rem 0;
    font-weight: bold;
}

.h1-small p {
    font-size: 16px;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .sr-car-logo-header{
        height: 50px;
        width: 100%;
    }
    .search-result-text{
        margin-top: 135% !important;
    }
    .homepage-contents-container {
        margin-top: 100%;
    }
    .banner-title-h1{
        left: 0% !important;
        position: absolute !important;
        top: 15% !important;
        right: 0% !important;
        color: #ffffff !important;
        font-size: 25px !important;
    }
    .form-background-image {
        background-image: none;
        background-color: #173141;
    }

    .card-form-bg-color {
        background: #173141;
    }
}

.footer-link-white {
    color: #ffffff !important;
}

.footer-link-white:hover {
    color: #ffd700;
}

.card-img-top {
    max-width: 90%;
    padding-top: 10px;
}