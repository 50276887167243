 

.banner-contents-container {
    margin-top: 0%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;   
}

.banner-title-h1{
  left: 25%;
  right: 25%;
  position: absolute;
  top: 45%;
  color: #ffffff;
  font-size: 49px;
}
.whatsapp-btn {
    position: fixed;
    right: 50px;
    top: 300px;
    z-index: 1000;
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform-origin: bottom right;
  }

  .whatsapp-btn a {
    display: flex;
    align-items: center;
    background-color: #25d366;
    color: white;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .whatsapp-btn a:hover {
    background-color: #20bf59;
    text-decoration: none;
  }

  .whatsapp-btn i {
    font-size: 25px;
    margin-right: 8px;
  }


  
  .intro-text h2 {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .services img {
    width: 100px;
    height: 100px;
  }
  
  .services h5 {
    margin-top: 15px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .road-trip ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .road-trip li {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  
  .contact-details p {
    margin-bottom: 10px;
  }
  
  .faq .accordion-button {
    font-weight: bold;
  }
  
  .footer {
    background-color: #f8f9fa;
  }
  
  .accordion-button::before{
    display: none;
  }
  .accordion {
    border:none; /* Optional: Add border to the accordion */
  }
  
  .accordion-button {
    background-color: #fff !important;
    display: flex;
    justify-content: space-between; /* Space between title and icon */
    align-items: center; /* Center items vertically */
    padding: 10px 20px; /* Adjust padding as needed */
    border: none; /* Remove default button border */
    background: transparent; /* Set background to transparent */
    cursor: pointer; /* Change cursor to pointer */
    width: 100%; /* Make button take full width */
  }
  
  .accordion-title {
    flex-grow: 1; /* Allow title to take up available space */
    text-align: left; /* Align title text to the left */
    font-weight: bold;
  }
  
  .accordion-icon {
    margin-left: 10px; /* Add space between the title and icon */
    display: flex; /* Flex to align the icons properly */
    align-items: center; /* Center icons vertically */
  }
  
  .accordion-button i {
    font-size: 18px; /* Adjust icon size */
  }
  
  .accordion-content {
    padding: 20px 0px !important;  
    border-bottom: 1px solid #ddd;
  }
  .accordion-button:not(.collapsed){
    box-shadow: none !important;
    padding: 0px !important;
  }