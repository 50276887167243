

.sr-contact-container {
  background-color: #FFFFFF;
  /* White background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Soft shadow for depth */
  text-align: center;
  max-width: 400px;
  /* Adjust based on your layout */
  margin: 20px auto;
  /* Center the container and add some margin */
}
.content-section {
  padding: 20px; 
  border-radius: 5px;
  margin-bottom: 20px;
}
.icon-text { 
  display: flex;
  align-items: start;
  margin-bottom: 10px; /* Space between lines */
}
.icon-text i {
  font-size: 24px; /* Adjust icon size here */
  color: #17a2b8; /* Bootstrap's info color */
  margin-right: 10px; /* Space between icon and text */
}
.icon-text b {
  margin-right: 5px; /* Space between label and text */
}
.sr-contact-title {
  color: #007bff;
  /* SR brand blue */
  margin-bottom: 15px;
}

.sr-contact-info {
  color: #333;
  /* Dark grey for text */
}

.sr-contact-item {
  margin: 10px 0;
  font-size: 16px;
}

.sr-contact-item a {
  color: #007bff;
  /* Link color */
  text-decoration: none;
  /* No underline */
}

.sr-contact-item a:hover {
  text-decoration: underline;
  /* Underline on hover */
}